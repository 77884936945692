<template>
    <div>
        <LoadingComponent :isLoading="loading" />
        <div class="w-[100%] py-5 px-5 bg-white mb-5 flex items-center">
            <div
                class="flex items-center justify-center flex-1 h-[50px] text-center border-r border-red-50"
            >
                會員儲值金統計：$ {{ balanceSummary | formatCurrency }}
            </div>
            <div
                class="flex items-center justify-center flex-1 h-[50px] text-center"
            >
                會員折抵金統計：$ {{ voucherSummary | formatCurrency }}
            </div>
        </div>
        <div class="overflow-x-auto bg-white md:rounded-lg">
            <table class="myTable">
                <!-- <thead>
                    <tr>
                        <th>會員暱稱</th>
                        <th>儲值金餘額</th>
                        <th>折抵金餘額</th>
                        <th>編輯</th>
                    </tr>
                </thead> -->
                <TableHead
                    ref="TableHead"
                    :labels="tableLabels"
                    @changeSort="changeSort"
                />
                <tbody>
                    <tr
                        v-for="i in datas"
                        :key="i.id"
                        class="text-center transition duration-200"
                    >
                        <td>{{ i.name }}</td>
                        <td>$ {{ i.wallet.balance | formatCurrency }}</td>
                        <td>$ {{ i.wallet.voucher | formatCurrency }}</td>
                        <td class="cursor-pointer" @click="goEdit(i.id)">
                            <i class="far fa-edit"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="flex justify-center mt-3 mb-3">
                <MyPagination
                    :paginationData="paginationData"
                    :currentPage="currentPage"
                    @onPageSizeChange="pageSizeChange"
                    @onCurrentPageChange="currentPageChange"
                />
            </div>
        </div>
    </div>
</template>

<script>
// import composition-api
import {
    ref,
    reactive,
    computed,
    onActivated,
    onMounted,
    watch,
    getCurrentInstance,
    defineExpose,
    onBeforeUnmount
} from "@vue/composition-api";
// 分頁組件
import MyPagination from "@/components/MyPagination.vue";
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
// table Head
import TableHead from "@/components/table/TableHead.vue";
// init search Filter options
import accountApi from "@/api/cms/accountApi";
// element ui message
import { Message } from "element-ui";
const defaultFilterOpts = {
    id: "",
    sort_by: "", // created_at or started_at or gross_price
    sort_type: "", // asc or desc
};
export default {
    name: "MemberWallets",
    components: {
        LoadingComponent,
        MyPagination,
        TableHead,
    },
    setup() {
        // loading state
        const loading = ref(false);
        const voucherSummary = ref(0);
        const balanceSummary = ref(0);
        const datas = ref([]);
        const paginationData = ref({
            limit: 10,
            total: 0,
        });
        const currentPage = ref(1);
        const filterOpts = ref({ ...defaultFilterOpts });
        const dateInputs = ref([]);
        const tableLabels = ref([
            {
                label: "會員暱稱",
            },
            {
                label: "儲值金餘額",
                isSort: true,
                val: "created_at",
            },
            {
                label: "折抵金餘額",
            },
            {
                label: "編輯",
            },
        ]);

        // computed
        const filterData = computed(() => {
            const filter = {};
            for (const key in filterOpts.value) {
                if (filterOpts.value[key]) {
                    filter[key] = filterOpts.value[key];
                }
            }
            const result = {
                ...filter,
                limit: paginationData.value.limit,
                page: currentPage.value,
            };
            return result;
        });

        // methods
        const getList = async () => {
            loading.value = true;
            const { status, data } = await accountApi
                .GetMemberWalletsAPI(filterData.value)
                .then((res) => {
                    return { data: res.data, status: res.status };
                })
                .catch(() => {
                    Message({
                        type: "error",
                        message: "取得資料失敗",
                    });
                })
                .finally(() => {
                    loading.value = false;
                });
            if (status === 200) {
                datas.value = [...data.data];
                paginationData.value = {
                    limit: data.per_page,
                    total: data.total,
                };
            }
        };

        const getSummary = async () => {
            loading.value = true;
            const { status, data } = await accountApi
                .GetMemberWalletSummaryAPI()
                .then((res) => {
                    return { data: res.data, status: res.status };
                });
            if (status === 200) {
                balanceSummary.value = data.total_balance;
                voucherSummary.value = data.total_voucher;
            }
        };

        const goSearch = () => {
            currentPage.value = 1;
            getList();
        };

        const changeSort = (val) => {
            filterOpts.value.sort_by = val.sortBy;
            filterOpts.value.sort_type = val.orderBy;
            getList();
        };

        const reset = () => {
            dateInputs.value = [];
            filterOpts.value = { ...defaultFilterOpts };
            this.$refs.TableHead.reset();
            currentPage.value = 1;
            paginationData.value = {
                limit: 10,
                total: 0,
            };
            getList();
        };

        const pageSizeChange = (val) => {
            // 更新每頁取得幾筆資料參數
            paginationData.value.limit = val;
            // 當前頁面設定為第一頁
            currentPage.value = 1;
            getList();
        };

        const currentPageChange = (val) => {
            // 設定目前分頁為第幾面
            currentPage.value = val;
            getList();
        };

        const goEdit = (customerID) => {
            window.open(`/#/user_list/member_update/${customerID}`);
        };

        onMounted(() => {
            getSummary();
            getList();
        });

        onActivated(() => {
            getSummary();
            getList();
        });

        return {
            // data
            loading,
            datas,
            paginationData,
            currentPage,
            filterOpts,
            dateInputs,
            tableLabels,
            voucherSummary,
            balanceSummary,
            // computed
            filterData,
            // methods
            getList,
            goSearch,
            changeSort,
            reset,
            pageSizeChange,
            currentPageChange,
            goEdit,
        };
    },
};
</script>

<style lang="scss" scoped>
label {
    color: #757575;
    font-size: 16px;
    font-weight: 400;
    line-height: 34px;
}

::v-deep .el-input__inner {
    max-width: 100%;
}

::v-deep .el-range-separator {
    width: 20px;
}
</style>
